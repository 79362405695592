<template>
  <el-dialog
    title="发型师 - 员工上牌"
    :visible.sync="dialogVisible"
    width="30%"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <div class="list">
      <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="input"
        size="small"
      >
      </el-input>
      <div class="box">
        <ul>
          <li v-for="(item, index) in 10" :key="index">
            <div class="image">江</div>
            <div class="name">
              <span>8</span>
              <span>江志峰</span>
            </div>
            <div class="type">高级发型师</div>
          </li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <span>
        <el-checkbox v-model="checked">全选</el-checkbox>
        <span class="ren">已选0人</span>
      </span>
      <span>
        <el-button size="mini">取消</el-button>
        <el-button ref="submitButton" type="primary" size="mini" disabledd
          >上牌</el-button
        >
      </span>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialogVisible: false,
      input: "",
    };
  },
};
</script>

<style lang="less" scoped>
.list {
  height: 500px;
  background-color: #f9fafc;
  overflow-y: scroll;
  .el-input {
    margin-bottom: 20px;
  }
  .box {
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    ul > li {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 120px;
      height: 150px;
      border: 1px solid #c0ccda;
      border-radius: 5px;
      background-color: #ffffff;
      margin-right: 16px;
      margin-bottom: 16px;
    }
    ul > li:nth-child(4n) {
      margin-right: 0;
    }
    .image {
      width: 46px;
      height: 46px;
      background-color: #efefef;
      border-radius: 50%;
      margin-bottom: 10px;
      text-align: center;
      line-height: 46px;
      font-size: 18px;
    }
    .name {
      span:last-child {
        margin-left: 5px;
      }
    }
    .type {
      font-size: 12px;
      color: #a6b0bf;
      margin-top: 5px;
    }
  }
}
.list::-webkit-scrollbar {
  width: 0;
  display: none;
}
.bottom {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  .ren {
    margin-left: 10px;
  }
}
</style>
