<template>
	<div>
		<div class="row" 
		v-for="(item,index) in rowData" :key="index"
		v-dragging="{ list: rowData, item: item, group: 'item'}">
			<div class="title">
				<span>
					<i class="el-icon-rank"></i>
				</span>
				<span>{{item.name}}</span>
				<span>
					<i class="el-icon-more"></i>
				</span>
			</div>
			<div class="scroll" :ref="`scroll_${index}`">
				<span class="arrow-left" @click="onScrollLeft(index,(item.colData.length+2)*140)">
					<i class="el-icon-arrow-left"></i>
				</span>
				<span class="arrow-right" @click="onScrollRight(index,(item.colData.length+2)*140)">
					<i class="el-icon-arrow-right"></i>
				</span>
				<div class="container" :style=" `width: ${(item.colData.length+2)*140}px;`">
					<div v-for="(itemChild,indexChild) in item.colData" :key="indexChild"
				  v-dragging="{ list: item.colData, item: itemChild, group: `itemChild${index}`}">
						<div class="image">张</div>
						<div class="text">
							<p>
								<span class="right">{{itemChild.id}}</span>
								<span>张三</span>
							</p>
							<p>高级发型师</p>
						</div>
						<div class="time">
							<p>状态持续</p>
							<p>
								<span>11</span>
								<span>00</span>
							</p>
						</div>
						<el-popover
							placement="bottom"
							width="130"
							trigger="hover">
							<div class="round">
								<p>更改状态</p>
								<p>
									<el-button type="info" round size="mini">下牌</el-button>
								</p>
							  <p>
							  	<el-button type="danger" round size="mini">服务中</el-button>
							  </p>
								<p>
									<el-button type="primary" round size="mini">临休</el-button>
								</p>
							</div>
							<div class="state" slot="reference">待服务</div>
						</el-popover>
					</div>
					<div @click="addRound" class="add">+</div>
				</div>
			</div>
		</div>
		<BoardDialog ref="BoardDialog"></BoardDialog>
	</div>
</template>

<script>
	import draggable from 'vuedraggable'
	import BoardDialog from './components/board-dialog.vue'
	export default {
		components: { draggable , BoardDialog},
		data() {
			return {
				rowData: [
					{ id: 1, 
					  name: '洗护师' , 
					  colData:[
							{id:10, child: '80'},
							{id:11, child: '90'},
							{id:12, child: '100'},
							{id:13, child: '80'},
							{id:14, child: '90'},
							{id:15, child: '100'},
							{id:16, child: '80'},
							{id:17, child: '90'},
							{id:18, child: '100'},
							{id:19, child: '80'},
							{id:20, child: '90'},
							{id:21, child: '100'},
							{id:22, child: '90'},
							{id:23, child: '100'}
						],
					},
					{ id: 2, name: '发型师' , colData:[{id:13, child: '200'},{id:14, child: '210'},{id:15, child: '230'}]},
					{ id: 3, name: '技师' , colData:[]},
					{ id: 4, name: '18天宝' , colData:[]}
				],
				scroll_y: 0
			}
		},
		mounted() {
				this.$dragging.$on('dragged', (event) => {
					  console.log(event)
					  console.log('移动的元素draged:',event.draged)
					  console.log('重新排列的数组value:',event.value.list)
					  /* var id = null //第一级id
						var i = null //第一级 下标
					  var obj = null 
					  this.rowData.forEach((item,index)=>{
							 item.colData.forEach((itemChild,indexChild)=>{
								  if(itemChild.id === value.item.id){
										id = item.id
										i = index
										obj = value.item
									}
							 })
						})
						console.log(id,obj,i) */
				})
		},
		
		methods: {
			onScrollLeft(index,total){
				if(this.scroll_y >= total){
					console.log(this.scroll_y)
					this.$refs[`scroll_${index}`][0].scrollLeft = total
					this.scroll_y = total
				}else{
					this.$refs[`scroll_${index}`][0].scrollLeft = this.scroll_y + 140*3 
					this.scroll_y = this.scroll_y + 140*3
				}
				/* setTimeout(()=>{
					this.$refs[`scroll_${index}`][0].scrollLeft = this.scroll_y + 140*3 
					this.scroll_y = this.scroll_y + 140*3 
				},1000) */
			},	
			onScrollRight(index,total){
				if(this.scroll_y <= 0){
					this.$refs[`scroll_${index}`][0].scrollLeft = 0
					this.scroll_y = 0
				}else{
					this.$refs[`scroll_${index}`][0].scrollLeft = this.scroll_y - 140*3 
					this.scroll_y = this.scroll_y - 140*3
				}
			},
			addRound(){
				this.$refs.BoardDialog.dialogVisible = true
			}
		}
	}
</script>

<style lang="less" scoped>
.row{
	display: flex;
	height: 20vh;
	width: 100%;
	margin-bottom: 20px;
	background-color: #f9fafc;
	border: 1px solid #e0e6ed;
	border-radius: 5px;
	overflow: hidden;
	position: relative;
	.title{
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		width: 60px;
		height: 100%;
		background-color: #eff2f7;
		>span{
			display: inline-block;
			width: 20px;
			text-align: center;
		}
		>span:first-child,
		>span:last-child{
			padding: 5px;
			width: 100%;
			box-sizing: border-box;
			cursor: pointer;
		}
		>span:first-child{
			font-size: 20px;
			font-weight: bold;
			color: #9eadc2;
			padding: 10px 0 0;
		}
		>span:first-child:hover{
			color: #5e6d82;
		}
		>span:last-child{
			background-color: #e5e9f2;
		}
	}
}	
.row:last-child{
	margin-bottom: 0;
}	
.scroll{
	flex: 1;
	overflow-x: auto;
	transition: all 0.5; 
	>span{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		z-index: 999;
		width: 35px;
		height: 35px;
		text-align: center;
		line-height: 35px;
		border-radius: 50%;
		font-size: 18px;
		color: #FFFFFF;
		background-color: rgba(0,0,0,0.1);
		display: none;
		cursor: pointer;
	}
	.arrow-right{
		right: 10px;
	}
	.container{
		//display: flex;
		padding: 10px;
		height: 100%;
		box-sizing: border-box;
		>div{
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			float: left;
			width: 130px;
			height: 100%;
			background-color: #4caf50;
			margin-right: 10px;
			border-radius: 5px;
			transition: all 0.3s; 
			color: #FFFFFF;
			cursor: pointer;
		}
		>div:hover{
			transform: translateZ(6px);
			box-shadow: 0 0 15px rgba(0,0,0, 0.8);
		}
		.image{
			height: 46px;
			width: 46px;
			border-radius: 50%;
			text-align: center;
			line-height: 46px;
			background-color: #efefef;
			color: #666666;
		}
		.text{
			text-align: center;
			>p:nth-child(1){
				margin-top: 5px;
			}
			>p:nth-child(2){
				font-size: 12px;
			}
			.right{
				margin-right: 5px;
			}
		}
		.time{
			margin-top: 5px;
			font-size: 12px;
			text-align: center;
		  span{
				display: inline-block;
				padding: 0 2px;
				background-color: #FFFFFF;
				color: #4caf50;
				border-radius: 2px;
				margin-top: 2px;
			}
			span:first-child{
				margin-right: 2px;
			}
		}
		.state{
			margin-top: 5px;
		}
		.add{
			font-size: 30px;
			font-weight: bold;
			margin-right: 0;
		}
	}
}	
.scroll:hover>span{
	display: block;
}
.scroll::-webkit-scrollbar-track {
  //background-color: #ffffff;
}
.scroll::-webkit-scrollbar {
  width: 0;
	display: none;
}
.scroll::-webkit-scrollbar-thumb {
  //background-color: #FFFFFF;
}
</style>
